import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from '../../../themes/default-theme';
import { ButtonBase } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import Link from '../NextLink';
import NewNavSearch from './newNavSearch';
import searchIcon from '../../../assets/icons/new_search.svg';
import heartIcon from '../../../assets/icons/new_heart.svg';
import compareIcon from '../../../assets/icons/new_compare.svg';
import RangeIcon from '../../../assets/icons/range_icon.svg';
import NavArrowDown from '../../../assets/icons/new_nav_arrow_down.svg';
import NavArrowRight from '../../../assets/icons/new_nav_arrow_right.svg';
import NavClose from '../../../assets/icons/new_nav_close.svg';
import NewBurger from './NewBurger';
import SeriesCard from './SeriesCard';
import qs from 'qs';
import RespImg from '../../ui/RespImg';
import Text from '../../typography';
import ContactDetails from '../../../containers/NewContact/ContactDetails';
import NewSavedModel from '../../ui/NewSavedModel';
import topoPng from '../../../assets/topo-vertical-grey.svg';
import IconLink from '../../ui/IconLink';
import ExploreNav from './ExploreNav';
import { useRouter } from 'next/router';
import {sellsMotorhomes, sellsCampervans} from '../../../util/filterDealerMotorHomes'
import chevronRight from '../../../assets/icons/chevron-right-thick.svg';

const downSm = '@media (max-width: 939.95px)' // '@media (max-width: 839.95px)'
const upMd = '@media (min-width: 940px)' // '@media (min-width: 840px)'

const Wrapper = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    background-color: white;
    transition: top 300ms ease-in-out;

    ${props => props.fixed && `
        position: absolute;
    `}

    ${upMd} {
        padding-left: 16px;
        padding-right: 16px;
        z-index: 6;
    }
    ${theme.breakpoints.up('lg')} {
        padding-left: 40px;
        padding-right: 40px;
    }
    ${downSm} {
        ${props => props.active == 1 && `
            top: 0;
            height: 100%;
            width: 100%;
            outline: 0;
            z-index: 1200;
            position: fixed;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            background: #F7F7F7;

            &:before {
                content: '';
                height: 75px;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                background-color: white;
            }
        `}
    }
`

const Bg = styled.div`
    position: fixed;
    z-index: -10;
    height: 100%;
    width: 100%;
    left: 0;
    top: 100px;
    background: rgba(0,0,0,0.25);
    opacity: 0;
    visibility: hidden;
    transition: opacity 200ms ease-in-out;

    ${props => props.active && `
        z-index: 2;
        opacity: 1;
        visibility: visible;
    `}

    ${props => props.fixed && `
        display: none;
    `}
`

const Container = styled.div`
    max-width: 1400px;
    margin: auto;
`
const HeaderContainer = styled.div`
    padding: 12px 0;
    ${upMd} {
        display: flex;
        align-items: center;
        padding: 0;
        // padding: 12px 0 0;
    }
    ${downSm} {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`
const NavArrow = styled(ReactSVG)`
    display: inline-block;
    margin-left: 5px;
    position: relative;
    top: -1px;
    margin-left: 5px;
    svg {
        width: 15px;
        height: 8px;
    }

    ${downSm} {
        display: none;
    }
`

const NavItem = styled(ButtonBase)`
    display: inline-block;
    text-align: left;
    ${upMd} {
        &:after {
            content: '';
            background: #0170D4;
            position: absolute;
            height: 6px;
            width: 0;
            bottom: -3px;
            left: 0;
            transform: translateY(-50%);
            z-index: 115;
            transition: width 200ms ease-in-out;
        }
        ${props => props.menuhover && props.active == 1 && `
            &:after {
                width: 100%;
            }
        `}
    
        &:hover {
            a {
                color: ${theme.palette.charcoal};
                &:after {
                    
                }
            }
            svg {
                [fill] {
                    fill: ${theme.palette.charcoal};
                }
                [stroke] {
                    stroke: ${theme.palette.charcoal};
                }
            }
        }
        
        ${props => props.topmenu && `
            min-height: 87px;
            a {
                font-weight: 700;
                font-size: 14px;
                line-height: 19px;
                text-align: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                text-decoration: none;
                color: #758094;
            }
        `}
        ${props => props.tabstitle && `
            a {
                font-weight: 700;
                font-size: 15px;
                text-transform: capitalize;
                text-decoration: none;
                display: block;
                color: #758094;
            }
        `}
        ${props => props.tabslinks && `
            a {
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
                text-transform: uppercase;
                text-decoration: none;
                display: block;
                color: #0170D4;
            }
        `}
        
        ${props => props.desktoparrow && `
            a {
                display: flex;
                align-items: center;
                gap: 5px;
                &:after {
                    content: url(${NavArrowDown});
                    font-size: 0;
                    line-height: 0;
                }
            }
        `}
    }
    ${downSm} {
        width: 100%;
        ${props => props.tabstitle && `
            a {
                font-weight: 700;
                margin-bottom: 24px;
                font-size: 15px;
                line-height: 20px;
                text-transform: capitalize;
                text-decoration: none;
                color: #758094;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:after {
                    content: url(${NavArrowRight});
                    font-size: 0;
                    line-height: 0;
                }
            }
        `}
        ${props => props.tabslinks && `
            a {
                font-weight: 700;
                margin-bottom: 24px;
                font-size: 15px;
                line-height: 21px;
                text-transform: uppercase;  
                text-decoration: none;        
                color: #0170D4;
                display: flex;
                align-items: center;
                gap: 11px;
                &:after {
                    content: url(${NavArrowRight});
                    font-size: 0;
                    line-height: 0;
                    filter: brightness(0) saturate(100%) invert(25%) sepia(85%) saturate(2093%) hue-rotate(194deg) brightness(97%) contrast(99%);
                }
            }
        `}
        ${props => props.back && `
            a {
                font-weight: 700 !important;
                font-size: 12px !important;
                line-height: 16px !important;
                text-align: right;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #758094;
                display: flex !important;
                align-items: center !important;
                gap: 11px;
                padding-bottom: 30px;
                border-bottom: 1px solid #D6D6D6;
                &:before {
                    content: url(${NavArrowRight});
                    transform: scale(-1);
                    font-size: 0;
                    line-height: 0;
                }
            }
        `}
        ${props => props.rangemobile && `
            a {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                text-decoration: none;
                color: #758094;
            }
        `}
        ${props => props.mobilearrow && `
            a {
                display: flex;
                align-items: center;
                gap: 5px;
                &:after {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background-image: url(${NavArrowDown});
                    font-size: 0;
                    line-height: 0;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                }
            }
        `}
        ${props => props.active == 1 && props.mobilearrow && `
            a {
                &:after {
                    transform: scale(-1);
                }
            }
        `}
`

const LogoSvg = styled.img`
    ${upMd} {
        width: 78px;
        height: 47px;
        margin-right: 26px;

        ${props => props.dealer &&`   
            width: 156px;
            height: auto;
        `}
    }
    ${theme.breakpoints.up('lg')} {
        margin-right: 65px;
        width: 103px;
        height: 63px;
        ${props => props.dealer &&`   
            width: 206px;
            height: auto;
        `}
    }
    ${downSm} {
        width: 78px;
        height: 47px;
        ${props => props.dealer &&`   
            max-width: 156px;
            width: 156px;
            max-height: 38px;
            height: auto;
        `}
    }
`

const MainNavContainer = styled.div`
    ${upMd} {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
`

const MainNavInnerContainer = styled.div`
    ${upMd} {
 
    }
    ${downSm} {

    }
`
const MainNavLinks = styled.div`
    ${upMd} {
        display: flex;
        align-items: center;
    }
    ${downSm} {
        display: flex;
        align-items: center;
        gap: 29px;
`

const RangeContainer = styled.div`

`
const NavContainer = styled.div`
    ${upMd} {
        margin-left: 26px;
        margin-right: 26px;
        display: flex;
        justify-content: space-between;
    }
    ${theme.breakpoints.up('lg')} {
        margin-left: 38px;
        margin-right: 38px;
    }
`
const NavContainerFirst = styled.div`
    ${upMd} {
        display: flex;
        gap: 26px;
    }
    ${theme.breakpoints.up('lg')} {
        gap: 38px;
    }
    ${downSm} {
        button {
            a {
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                text-decoration: none;
                margin-bottom: 20px;
                color: #0170D4;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:after {
                    content: url(${NavArrowRight});
                    font-size: 0;
                    line-height: 0;
                    filter: brightness(0) saturate(100%) invert(25%) sepia(85%) saturate(2093%) hue-rotate(194deg) brightness(97%) contrast(99%);
                }
            }
        }
    }
`
const NavContainerSecond = styled.div`
    ${upMd} {
        display: flex;
        gap: 15px;
    }
    ${theme.breakpoints.up('lg')} {
        gap: 38px;
    }
    ${downSm} {
        padding-top: 20px;
        border-top: 1px solid #D6D6D6;
        button {
            a {
                font-weight: 800;
                font-size: 18px;
                line-height: 24px;
                text-transform: uppercase;
                text-decoration: none;
                color: #758094;
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:after {
                    content: url(${NavArrowRight});
                    font-size: 0;
                    line-height: 0;
                }
            }
        }
    }
`
const NavIcon = styled.div`
    position: relative;
    ${props => props.display == 'flex' && `
        ${upMd} {
            display: flex;
            gap: 13px;
            align-items: center;
        }
    `}
    ${upMd} {
  
        > i {
            position: absolute;
            right: -13px;
            color: ${theme.palette.white};
            background-color: rgb(1, 112, 212);
            top: -11px;
            display: inline-block;
            height: 19px;
            width: 19px;
            font-size: 12px;
            line-height: 19px;
            border-radius: 100%;
            text-align: center;
            font-style: normal;
        }
    
    }
`

const NavItemIcon = styled(ReactSVG)`
    ${upMd} {
        > div {
            display: flex;
        }
    }
`

const BurgerWrapper = styled.div`
    height: 14px;
    display: flex;
`

const MainNavContentContainer = styled.div`
    z-index: 3; 
    ${upMd} {
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: 0;
        padding: 0 100px;
        background: #F7F7F7;
        z-index: 115;
        background-image: url(${topoPng});
        background-repeat: no-repeat;
        background-position: -14px;
        ${props => props.height == 1 && `
            height: calc(100vh - 115px);
            max-height: 735px;
            overflow-y: auto;
            padding-bottom: 34px;
            > div {
                padding-bottom: 0;
            }
        `
        }
    }

    ${theme.breakpoints.down('md')} {
        // padding: 0 40px;
        background-position: top left -48px;
        
    }

    ${downSm} {
        position: absolute;
        width: 100%;
        left: 0;
        margin-top: 30px;
        background: #F7F7F7;
    }
`
const MainNavContentContainerMobile = styled.div`
    ${downSm} {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        margin-top: 30px;
        padding: 30px 20px 0;
        background: #F7F7F7;
        z-index: 115;
    }
`
const MainNavContent = styled.div`
    ${upMd} {
        max-width: 1400px;
        width: 100%;
        margin: auto;
        position: relative;
        ${props => props.tabs && props.active == 1 && `
            padding: 65px 0 65px;
        `}
        ${props => props.normal && props.active == 1 && `
            padding: 68px 0 48px;
        `}
        ${props => props.display == 'flex' && `
            > div {
                display: flex;
                gap: 71px;
            }
        `}

        ${props => props.search && props.active == 1 && `
             padding: 68px 0;
             > div {
                max-width: 696px;
                margin: auto;
             }
        `}
        ${props => props.saved && props.active == 1 && `
            padding: 68px 0;
            > div {
                max-width: 921px;
                margin: auto;
                h3 {
                    margin-bottom: 36px;
                }
            }
        `}
        ${props => props.dealer && props.active == 1 && `
            padding: 68px 0;
            > div {
                max-width: 811px;
                margin: auto;
                > h3 {
                    text-align: left;
                }
            }
        `}
    }
    ${downSm} {
        ${props => props.tabs && props.active == 1 && `
            background: #F7F7F7;
            width: 100%;
            height: 100%;
            padding: 30px 20px 0;
        `}
        ${props => props.normal && `
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            z-index: -1;
            visibility: hidden;
            opacity: 0;
            transform: translateX(-300%);
            background: #F7F7F7;
            transition: transform 300ms ease-in-out;
        `}
        ${props => props.normal && props.active == 1 && `
            position: absolute;
            visibility: visible;
            opacity: 1;
            z-index: 116;
            transform: translateX(0);
            background: #F7F7F7;
            height: 100%;
            display: table;
            padding: 30px 20px 0;
        `}
    }
`
const MainNavContentInner = styled.div`
    ${props => props.normal && `
        max-width: 1050px;
        margin: auto;
    `}
    
    ${props => (props.display === false || props.display === 'false') && `
        display: none!important;
    `}

    ${upMd} {
        ${props => props.flex && `
            display: flex;
            justify-content: space-between;
        `}
        ${props => (props.display === false || props.display === 'false') && `
            display: none;
        `}
    }
    > h3 {
        font-weight: 800;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #0170D4;
        ${upMd} {
            font-size: 24px;
            line-height: 24px;
            text-align: center;
        }
        ${downSm} {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: normal;
        }
    }
`
const DesktopNavClose = styled(ReactSVG)`
    position: absolute;
    right: 0;
    top: 22px;
    cursor: pointer;
    z-index: 115;
    &:hover {
        svg {
            [fill] {
                fill: ${theme.palette.charcoal};
            }
        }
    }
`
const MainNavFirst = styled.div`
    ${upMd} {
        width: 100%;
        display: flex;
        gap: 80px;
        > div {
            width: 33.33%;
            max-width: 190px;
        }
    }
`

const MainNavSecond = styled.div`
    ${upMd} {
        max-width: 275px;
    }
`

const NavContent = styled.div`

`

const MainNavContentItems = styled.div`
    ${upMd} {
        width 25.7%;
        ${props => props.paddingBottom && `
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #D9D9D9;
        `}
    }
`
const MainNavContentItemsLinks = styled.div`
    a {
        padding-bottom: 0 !important;
        margin-bottom: 15px;
        padding-right: 0 !important;
    }
    > * {
        &:last-child {
            a {
                margin-bottom : 0;
            }
        }
    }
    ${upMd} {
        > div {
            &:first-child {
                border-top: 1px solid #D9D9D9;
                padding-top: 15px; 
                margin-top: 15px;    
            }    
        }   
    }
    ${downSm} {
        padding-top: 30px;
        border-top: 1px solid #D6D6D6;
    }
`

const MainNavContentItem = styled.div`
    ${theme.breakpoints.up('md')} {
        ${props => props.width && props.width == 'full' && `
            width: 100%!important;
        `
        }
    }
    ${upMd} {
        ${props => props.tabs && `
            a {
                padding: 15px 34px 15px 0;
            }
            position: relative;
            button {
                width: 100%;
                min-width: 100px;
                text-align: left;
                justify-content: left;
                position: relative;
                width: calc(100% + 27px);
                left: -27px;
                padding-left: 27px;
            }
        `}
        ${props => props.normal && `
            > button {
                margin-bottom: 23px;
                a {
                    font-weight: 700;
                    font-size: 15px;
                    line-height: 8px;
                    text-align: center;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    text-decoration: none;
                    color: #2E3238;
                }
            }
            > div {
                > button {
                    display: block;
                    &:not(:last-child) {
                        margin-bottom: 23px;
                    }
                    a {
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 20px;
                        letter-spacing: 0.5px;
                        text-transform: capitalize;
                        text-decoration: none;
                        color: #2E3238;
                        opacity: 0.8;
                    }
                }
            }
    `}
        ${props => props.active == 1 && `
            background-color: #fff;

            button {
                background-color: white;
                background-image: url(${NavArrowRight});
                background-position: calc(100% - 34px);
                background-repeat: no-repeat;
            }
        `}
    }
    ${downSm} {
        ${props => props.normal && `
            &:first-child {
                button {
                    a {
                        border-bottom: 0;
                    }
                }
            }
            &:not(:first-child) {
                margin-bottom: 20px;
                > button {
                    a {
                        font-weight: 700;
                        display: block;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        text-decoration: none;
                        color: #2E3238;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #D6D6D6;
                    }
                }
                > div {
                    button {
                        a {
                            font-weight: 600;
                            display: block;
                            font-size: 14px;
                            line-height: 40px;
                            text-decoration: none;
                            color: #2E3238;
                        }

                    }
                }
            }
        `}
    }
`
const MainNavContentSeries = styled.div`
    ${upMd} {
        width: 68%;
        padding-right: 27px;
        ${props => props.paddingBottom && `
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #D9D9D9;
        `}
    }
    ${downSm} {
        position: fixed;
        left: 0;
        top: 0;
        z-index: -1;
        width: 100%;
        visibility: hidden;
        padding: 30px 20px 0;
        opacity: 0;
        background: #F7F7F7;
        transform: translateX(-300%);
        transition: transform 300ms ease-in-out;
        ${props => props.active == 1 && `
            position: absolute;
            visibility: visible;
            background: #F7F7F7;
            opacity: 1;
            z-index: 115;
            transform: translateX(0);
            height: 100%;
        `}
    }
`

const SeriesCardContainer = styled.div`
    ${props => (props.display === false || props.display === 'false') && `
        display: none!important;
    `}
    ${theme.breakpoints.up('md')} {
        display: flex;
        flex-wrap: wrap;
        > div {
            width: calc((100% - 32px)/2);
            margin-left: 0;
            margin-right: 0;
        }
    }
    ${downSm} {
        margin-bottom: 30px;
    }
    ${theme.breakpoints.up('lg')} {
        ${props => props.length == 'moreThanThree' && `
            > div {
                width: calc((100% - 32px)/3);
            }
        `}
        ${props => props.length == 'lessThanThree' && `
            > div {
                width: calc((100% - 32px)/3);
                &:nth-child(even) {
                    // margin-right: 10%;
                }
            }
        `}
    }
`
const NavCategoryLink = styled.div`
    ${props => (!props.display) && `
        display: none!important;
    `}
    ${upMd} {
        justify-content: flex-end;
    }
    ${downSm} {
        padding-bottom: 30px;
        > * {
            width: auto;
        }
    }
    display: flex;
    gap: 11px!important;
    align-items: center;
    a {
        color: ${theme.palette.vividBlue};
        font-style: normal;
        font-weight: 700;
        line-height: 21px;
        text-transform: uppercase;
        text-decoration: none;
        ${upMd} {
            font-size: 15px;
        }
        ${downSm} {
            font-size: 15px;
        }
    }
`

const ChevronIcon = styled.div`
    display: inline-block;
    vertical-align: middle;
    position: relative;
    ${upMd} {
        top: 1px;
    }
    ${downSm} {
        top: 2px;
    }
    svg {
        height: auto;
        width: 6px;

        [stroke] {
            stroke: ${theme.palette.vividBlue};
        }
    }
`

const MainNav = (props) => {
    const [fixed, setFixed] = useState(true);
    const [dealerExplore, setDealerExplore] = useState({})
    const router = useRouter();
  
    const {
        onSaved,
        itemsSaved,
        savedOpen,
        compareItems,
        refactorURL,
        countryCode,
        searchOpen,
        dealerOpen,
        mainNavToggle,
        desktopNavToggle,
        mobileNavToggle,
        tabContent,
        mobileTabContent,
        mobileExploreItems,
        handleDesktopNav,
        handleMobileExplore,
        handleNavClose,
        handleMobileMainToggle,
        navItems,
        rangeTitle,
        navArr,
        searchTitle,
        toggleSearch,
        handleSearchSubmit,
        handleTabHover,
        handleTabClick,
        dealer,
        defaultDealer,
        handleViewDealer,
        desktopView,
        mobileView,
        hasSubnav
    } = props;

    // console.log(savedOpen);

    const [showBuildAndPrice, setShowBuildAndPrice] = useState(false);

    const removeLastPath = (path) => {
        var arr = path.split('/');
        arr.pop();
        return( arr.join('/') );
    }

    useEffect(() => {
        if((document.location.hostname == 'www.jaycoadelaide.com.au') || (document.location.hostname == 'www.jaycoalbertpark.com.au') || (document.location.hostname.indexOf('jayco-com-au.au1.staticlyproject.com') > -1) || (document.location.hostname.indexOf('localhost') > -1)) {
          setShowBuildAndPrice(true);
        } else {
          setShowBuildAndPrice(false);
        }
    }, [])

    useEffect(() => {
        if (router && router.asPath) {
            const handleScroll = (e) => {
                var scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop
                
                if (scrollTop > lastScrollTop) {
                    setFixed(false);
                } else if (scrollTop < lastScrollTop) {
                    // upscroll code
                    setFixed(true);
                } // else was horizontal scroll

                lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
            }

            if (hasSubnav) {
                var lastScrollTop = 0;
                document.addEventListener("scroll", handleScroll);
            } 

            return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [router && router.asPath]);

    let dealerSitelastItem = null;
    let nav = props.nav ? [...props.nav] : [];

    if (process.env.DEALER_SITE) {
        dealerSitelastItem = nav.pop();
    }

    const [doesSellCampervans, setDoesSellCampervans] = useState(true);
    const [doesSellMotorhomes, setDoesSellMotorhomes] = useState(true);

    useEffect(() => {
        if (process.env.DEALER_SITE) {
            const _sellsCampervans = sellsCampervans();
            const _sellsMotorhomes = sellsMotorhomes();
            setDoesSellCampervans(_sellsCampervans);
            setDoesSellMotorhomes(_sellsMotorhomes);
        }
    }, [])

    const filteredRanges = (props.rangeNav && props.rangeNav.ranges) ? props.rangeNav.ranges.map((item) => {
        return {
            ...item,
            series: item.series.filter(series => {
                if (series.title && series.title.toLowerCase().indexOf('campervan') !== -1 && ! doesSellCampervans ) {
                    return false
                }
                if (series.title && series.title.toLowerCase().indexOf('motorhome') !== -1 && ! doesSellMotorhomes ) {
                    return false
                }
                return true
            })
        }
    }).filter(item => {
        return item.series.length > 0
    }) : []

    console.log('desktopNavToggle', desktopNavToggle)

    const rangeNavActive = (((mainNavToggle == navArr(props, 'rangeNav')) && desktopView) || ((mobileNavToggle == 'rangeNav') && mobileView)) ? true : false

    
    return (
        <>
  
            <Wrapper fixed={hasSubnav && !fixed ? true : false} active={mobileNavToggle && mobileView ? 1 : 0}>
                {(desktopView) &&
                    <Bg fixed={hasSubnav && !fixed ? true : false} active={(mainNavToggle == navArr(props, 'rangeNav')) || desktopNavToggle !== null || mainNavToggle == navArr(props, 'explore') || searchOpen || savedOpen}  onClick={(e) => { handleNavClose(0, e) }}/>
                }
                <Container>
                    <HeaderContainer>
                        <Link href={'/'}>
                            <a>
                                {process.env.DEALER_SITE !== true ? 
                                    <LogoSvg src="/images/desktop-logo.svg" alt="Jayco" />
                                    :
                                    <LogoSvg dealer={true} src="/images/dealer_logos/{{{data.template.dealer.website}}}/logo.svg" alt="{{data.template.dealer.name}}"/>
                                }  
                            </a>
                        </Link>
                        <MainNavContainer>
                            <MainNavInnerContainer>
                                {/* Top Menu Links Start */}
                                <MainNavLinks>
                                    {filteredRanges && filteredRanges.length > 0 &&
                                        <RangeContainer>
                                            <NavItem rangemobile="true" mobilearrow="true" topmenu="true" menuhover="true" active={(mainNavToggle == navArr(props, 'rangeNav') && desktopView) || ((mobileNavToggle == 'rangeNav') && mobileView) ? 1 : 0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    desktopView ?
                                                        handleDesktopNav(navArr(props, 'rangeNav'), 'rangeNav', e, null, 0)
                                                    :   handleMobileMainToggle('rangeNav', 100);
                                                }}
                                            >
                                                <Link href={'/'}>
                                                    {desktopView ?
                                                        <NavIcon display="flex"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                handleDesktopNav(navArr(props, 'rangeNav'), 'rangeNav', e, null, 100);
                                                            }}
                                                        >
                                                            <NavItemIcon src={RangeIcon} alt={'our range'} />
                                                            <Link href="javascript:;">
                                                                <a>
                                                                    {rangeTitle}
                                                                </a>
                                                            </Link>
                                                        </NavIcon>
                                                        :
                                                        <a onClick={(e) => {
                                                            e.preventDefault();
                                                            handleMobileMainToggle('rangeNav', 100);
                                                        }}>{rangeTitle}</a>
                                                    }
                                                </Link>
                                            </NavItem>
                                        </RangeContainer>
                                    }
                                    <NavContainer>
                                        {mobileView &&
                                            <NavItem>
                                                <BurgerWrapper onClick={(e) => {
                                                    e.preventDefault()
                                                    handleMobileMainToggle('mainNav', 100);
                                                }}>
                                                    <NewBurger active={mobileNavToggle == 'mainNav' ? 1 : 0} />
                                                </BurgerWrapper>
                                            </NavItem>
                                        }
                                        
                                        {((desktopView) || ((mobileNavToggle == 'mainNav') && (desktopNavToggle == null) && (mobileExploreItems == null) && (!searchOpen) && (!savedOpen) && (!dealerOpen) && mobileView)) &&
                                            <MainNavContentContainerMobile>
                                                <NavContainerFirst>
                                                    {nav && nav.length > 0 &&
                                                        nav.map((item, index) => {

                                                            const hideStockOn = (item.href && (item.href === '/stock')) ? ['jaycolansvale.com.au', 'jaycoalbertpark.com.au'] : []

                                                            let showStock = true;
                                              
                                                            if (process.browser && process.env.DEALER_SITE && item.href && (item.href === '/stock') ) {
                                                              showStock = (hideStockOn.indexOf(window.location.hostname.replace('www2.', '').replace('www.', '')) === -1)
                                                            }

                                                            if (showStock === false) {
                                                                return null
                                                            }

                                                            return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                <NavItem key={item.id ? item.id : index} topmenu="true" menuhover="true" active={desktopNavToggle == index ? 1 : 0}
                                                                    onClick={(e) => {
                                                                        if(item.items && item.items.length > 0){
                                                                            e.preventDefault()
                                                                            handleDesktopNav(navArr(props, 'nav'), 'nav', e, index, 0);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Link href={item.items && item.items.length > 0 ? '/' : item.href || '/'}>
                                                                        <a
                                                                            onClick={(e) => {
                                                                                if(item.items && item.items.length > 0){
                                                                                    e.preventDefault()
                                                                                }
                                                                                //handleDesktopNav(navArr(props, 'nav'), 'nav', e, index, 100);
                                                                            }}
                                                                        >{item.title}</a>
                                                                    </Link>
                                                                    {(item.items && item.items.length > 0) &&
                                                                        <NavArrow src={NavArrowDown} />
                                                                    }
                                                                </NavItem>
                                                            ) : null
                                                        })
                                                    }
                                                    {((props.explore && props.explore.items && props.explore.items.length > 0) || (props.explore && process.env.DEALER_SITE)) &&
                                                        desktopView ?
                                                        props.explore.title &&
                                                        <NavItem topmenu="true" menuhover="true" active={mainNavToggle == navArr(props, 'explore') ? 1 : 0}                                                                     onClick={(e) => {
                                                            e.preventDefault()
                                                            handleDesktopNav(navArr(props, 'explore'), 'explore', e, null, 0);
                                                        }}>
                                                            <Link href={'/'}>
                                                                <a
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        // handleDesktopNav(navArr(props, 'explore'), 'explore', e, null, 100);
                                                                    }}
                                                                >{props.explore.title}</a>
                                                            </Link>
                                                            <NavArrow src={NavArrowDown} />
                                                        </NavItem>
                                                        :
                                                        //Dealer mobile nav here...
                                                        <>
                                                            {props.explore && props.explore.items && props.explore.items.length > 0 && props.explore.items.map((item, index) => {
                                                                return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                    <NavItem key={item.id ? item.id : index} topmenu="true" menuhover="true">
                                                                        <Link href={'/'}>
                                                                            <a onClick={(e) => {
                                                                                e.preventDefault()
                                                                                handleMobileExplore(index, 100);
                                                                            }}>{item.title}</a>
                                                                        </Link>
                                                                    </NavItem>
                                                                ) : null
                                                            })}
                                                            {dealerExplore && dealerExplore.items && dealerExplore.items.length > 0 && dealerExplore.items.map((item, index) => {
                                                                return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                    <NavItem key={item.id ? item.id : index} topmenu="true" menuhover="true">
                                                                        <Link href={'/'}>
                                                                            <a onClick={(e) => {
                                                                                e.preventDefault()
                                                                                handleMobileExplore(index, 100);
                                                                            }}>{item.title}</a>
                                                                        </Link>
                                                                    </NavItem>
                                                                ) : null
                                                            })}
                                                        </>
                                                    } 
                                                </NavContainerFirst>
                                                {mobileView &&
                                                    <NavContainerSecond>
                                                        {process.env.DEALER_SITE !== true &&
                                                            <>
                                                                {dealer && dealer.data ?
                                                                    <NavItem topmenu="true" menuhover="true">
                                                                        <Link href={refactorURL(dealer.href)}>
                                                                            <a onClick={(e) => handleViewDealer(e)}>
                                                                                <span>
                                                                                    {dealer.title ? dealer.title : defaultDealer.title}
                                                                                </span>
                                                                            </a>
                                                                        </Link>
                                                                        
                                                                    </NavItem>
                                                                    :
                                                                    <NavItem topmenu="true" menuhover="true">
                                                                        <Link href={refactorURL(defaultDealer.href)}>
                                                                            <a>
                                                                                <span>
                                                                                    {defaultDealer.title && defaultDealer.title}
                                                                                </span>
                                                                            </a>
                                                                        </Link>
                                                                    </NavItem>
                                                                }
                                                            </>
                                                        }
                                                        <NavItem>
                                                            <Link href={compareItems && compareItems.length > 0 ? `/compare?${qs.stringify({ items: compareItems })}` : '/compare'}>
                                                                <a>
                                                                    Compare
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                        <NavItem onClick={(e) => onSaved(e, 100)} active={savedOpen ? 1 : 0}>
                                                            <a>
                                                                Saved
                                                            </a>
                                                        </NavItem>
                                                        <NavItem onClick={(e) => toggleSearch(e, 100)} active={searchOpen ? 1 : 0}>
                                                            <a>
                                                                Search
                                                            </a>
                                                        </NavItem>
                                                        {(process.env.DEALER_SITE && dealerSitelastItem) &&
                                                            <NavItem>
                                                                <Link href={refactorURL(dealerSitelastItem.href)}>
                                                                        <a>
                                                                            <span>
                                                                                {dealerSitelastItem.title && dealerSitelastItem.title}
                                                                            </span>
                                                                        </a>
                                                                    </Link>
                                                            </NavItem>
                                                        }
                                                    </NavContainerSecond>
                                                }
                                            </MainNavContentContainerMobile>
                                        }
                                    </NavContainer>
                                </MainNavLinks>
                                {/* Top Menu Links End */}
                                
                                <MainNavContentContainer height={((mainNavToggle == navArr(props, 'rangeNav')) && desktopView) ? 1 : 0}>
                                    <MainNavContent display="flex" tabs="true" active={rangeNavActive ? 1 : 0}>
                                            {filteredRanges.length > 0 &&
                                            <>
                                            <MainNavContentInner tabs="true" flex="true" display={rangeNavActive ? true : false}>
                                                <MainNavContentItems paddingBottom={true}>
                                                    {filteredRanges.map((item, index) => {
                                                        return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                            <MainNavContentItem key={item.id ? item.id : index} tabs="true" active={(tabContent == index || mobileView) ? 1 : 0}>
                                                                <NavItem tabstitle="true">
                                                                    <Link>
                                                                        <a onClick={(e) => {
                                                                                e.preventDefault()
                                                                                mobileView && handleTabClick(index, 100);
                                                                                desktopView && handleTabHover(index, 100);
                                                                            }}>
                                                                            {item.title}
                                                                        </a>
                                                                    </Link>
                                                                </NavItem>
                                                            </MainNavContentItem>
                                                        ) : null
                                                    })
                                                    }
                                                    {props.rangeNav.viewAll &&
                                                        <MainNavContentItem tabs="true">
                                                            <NavItem tabstitle="true">
                                                                <Link href={props.rangeNav.viewAll.href && refactorURL(props.rangeNav.viewAll.href)} target={props.rangeNav.viewAll.target && props.rangeNav.viewAll.target}>
                                                                    <a>
                                                                        {props.rangeNav.viewAll.title && props.rangeNav.viewAll.title}
                                                                    </a>
                                                                </Link>
                                                            </NavItem>
                                                        </MainNavContentItem>

                                                    }
                                                    <MainNavContentItemsLinks>
                                                        {props.rangeNav.links && props.rangeNav.links.length > 0 &&
                                                            props.rangeNav.links.map((item, index) => {
                                                                return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                    <MainNavContentItem key={item.id ? item.id : index} tabs="true">
                                                                        <IconLink colour="vividBlue" link={item} showBuildAndPrice={showBuildAndPrice ? 1 : 0} />
                                                                        {/* <NavItem tabslinks="true">
                                                                            <Link href={item.href && refactorURL(item.href)} target={item.target && item.target}>
                                                                                <a>
                                                                                    {item.title && item.title}
                                                                                </a>
                                                                            </Link>
                                                                        </NavItem> */}
                                                                    </MainNavContentItem>
                                                                ) : null
                                                            })
                                                        }
                                                    </MainNavContentItemsLinks>
                                                </MainNavContentItems>
                                                {filteredRanges && filteredRanges.length > 0 &&
                                                    <MainNavContentSeries active={(mobileView && mobileTabContent != null) ? 1 : 0} paddingBottom={true}>
                                                        {filteredRanges.map((item, index) => {
                                                            const display = (desktopView && tabContent == index) || (mobileView && mobileTabContent == index) ? true : false;
                                                            console.log('display', display);
                                                            return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                item.series && item.series.length > 0 &&
                                                                <>
                                                                <SeriesCardContainer key={item.id ? item.id : index} length={item.series.length > 3 ? 'moreThanThree' : 'lessThanThree'} display={display}>
                                                                    {mobileView &&
                                                                        <MainNavContentItem width="full">
                                                                            <NavItem back="true">
                                                                                <Link>
                                                                                    <a
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            mobileView && handleTabClick(null, 100);
                                                                                        }}>
                                                                                        Back
                                                                                    </a>
                                                                                </Link>
                                                                            </NavItem>
                                                                        </MainNavContentItem>
                                                                    }
                                                                        {item.series.map((child, index) => {
                                                                        return ((!child.assignedDomains || child.assignedDomains.length === 0 || child.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                            <SeriesCard 
                                                                                key={child.id ? child.id : index} 
                                                                                title={child.title} 
                                                                                href={child.href} 
                                                                                subTitle={child.subTitle} 
                                                                                sleeps={child.sleeps} 
                                                                                image={child.image} 
                                                                                mobileStyles={true} 
                                                                                hasBorder={true} 
                                                                                refactorURL={refactorURL} 
                                                                                hideSleeps={true} 
                                                                                mobilePadding={false}
                                                                                active={display && rangeNavActive}
                                                                            />
                                                                        ) : null
                                                                    })
                                                                    }
                                                                </SeriesCardContainer>
                                                         
                                                                    {item.series?.length > 0 && item.series[0].href && item.title &&
                                                                        <NavCategoryLink  display={((mobileView && mobileTabContent == index)) && (((mobileNavToggle == 'rangeNav') && mobileView)) ? 1 : 0}>
                                                                            <NavItem>
                                                                                <Link href={item.series[0].href ? removeLastPath(item.series[0].href) : '/'}>
                                                                                    <a  
                                                                                        onClick={(e) => {
                                                                                            !item.series[0].href && e.preventDefault()
                                                                                    }}>
                                                                                        View All {item.title}
                                                                                    </a>
                                                                                </Link>
                                                                            </NavItem>
                                                                            <ChevronIcon>
                                                                                <ReactSVG src={chevronRight} />
                                                                            </ChevronIcon>
                                                                        </NavCategoryLink>
                                                                    }
                                                                </>
                                                            ) : null
                                                        })
                                                        }
                                                    </MainNavContentSeries>
                                                }
                                                {desktopView &&
                                                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                }
                                            </MainNavContentInner>
                                            {filteredRanges.map((item, index) => {
                                                return (
                                                    item.series?.length > 0 && item.series[0].href && item.title &&
                                                    <NavCategoryLink  display={((desktopView && tabContent == index)) && (((mainNavToggle == navArr(props, 'rangeNav')) && desktopView)) ? 1 : 0}>
                                                        <NavItem>
                                                            <Link href={item.series[0].href ? removeLastPath(item.series[0].href) : '/'}>
                                                                <a  
                                                                    onClick={(e) => {
                                                                    !item.series[0].href && e.preventDefault()
                                                                }}>
                                                                    View All {item.title}
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                        <ChevronIcon>
                                                            <ReactSVG src={chevronRight} />
                                                        </ChevronIcon>
                                                    </NavCategoryLink>
                                                )
                                            })}
                                            
                                            </>
                                        }
                                    </MainNavContent>
                                   
                                    {
                                        navItems && navItems.length > 0 &&

                                        navItems.map((item, index) => {

                                            return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                <MainNavContent key={item.id ? item.id : index} normal="true" active={((desktopNavToggle == index && desktopView) || (mobileNavToggle == 'mainNav' && mobileView && desktopNavToggle == index)) ? 1 : 0}>
                                                    <MainNavContentInner normal="true" flex="true" display={((desktopNavToggle == index && desktopView) || (mobileNavToggle == 'mainNav' && mobileView && desktopNavToggle == index)) ? true : false}>
                                                        {item.items && item.items.length > 0 &&
                                                            <>
                                                                <MainNavFirst>
                                                                    {mobileView &&
                                                                        <MainNavContentItem normal="true">
                                                                            <NavItem back="true">
                                                                                <Link>
                                                                                    <a
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault()
                                                                                            handleDesktopNav(navArr(props, 'nav'), 'nav', e, index, 100);
                                                                                        }}>
                                                                                        Back
                                                                                    </a>
                                                                                </Link>
                                                                            </NavItem>
                                                                        </MainNavContentItem>
                                                                    }
                                                                    {item.items.map((item, index) => {
                                                                        return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                            <MainNavContentItem key={item.id ? item.id : index} normal="true">
                                                                                <NavItem>
                                                                                    <Link href="javascript:;">
                                                                                        <a>
                                                                                            {item.title}
                                                                                        </a>
                                                                                    </Link>
                                                                                </NavItem>
                                                                                {item.links && item.links.length > 0 &&
                                                                                    <NavContent>
                                                                                        {item.links.map((item, index) => {
                                                                                            return ((!item.assignedDomains || item.assignedDomains.length === 0 || item.assignedDomains.indexOf(props.assignedDomain) !== -1)) ? (
                                                                                                <NavItem key={item.id ? item.id : index}>
                                                                                                    <Link href={item.href && refactorURL(item.href)} target={item.target && item.target}>
                                                                                                        <a>
                                                                                                            {item.title}
                                                                                                        </a>
                                                                                                    </Link>
                                                                                                </NavItem>
                                                                                            ) : null
                                                                                        })
                                                                                        }
                                                                                    </NavContent>
                                                                                }
                                                                            </MainNavContentItem>
                                                                        ) : null
                                                                    })
                                                                    }
                                                                </MainNavFirst>
                                                                {desktopView &&
                                                                    <MainNavSecond>
                                                                        {item.image &&
                                                                            <RespImg key={index}
                                                                                {...item.image}
                                                                                visible={(desktopNavToggle == index) ? true : false}
                                                                            />
                                                                        }
                                                                    </MainNavSecond>
                                                                }
                                                            </>
                                                        }
                                                        {desktopView &&
                                                            <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                        }
                                                    </MainNavContentInner>
                                                    {/* UNDO DEALERS NAV START */}
                                                    {/* <DealerNavList 
                                                        item={item}
                                                        desktopNavToggle={desktopNavToggle}
                                                        index={index}
                                                        desktopView={desktopView}
                                                        mobileNavToggle={mobileNavToggle}
                                                        mobileView={mobileView}
                                                        handleNavClose={handleNavClose}
                                                        handleDesktopNav={handleDesktopNav}
                                                        refactorURL={refactorURL}
                                                    /> */}
                                                    {/* UNDO DEALERS NAV END */}
                                                
                                                </MainNavContent>
                                            ) : null
                                        })
                                    }


                                    <MainNavContent normal="true" active={((mainNavToggle == navArr(props, 'explore') && desktopView) || (mobileNavToggle == 'mainNav' && (mobileExploreItems != null) && mobileView)) ? 1 : 0}>
                                        
                                            {((props.explore && props.explore.items && props.explore.items.length > 0) || (process.env.DEALER_SITE && props.explore)) &&
                                                <ExploreNav 
                                                    item={props.explore}
                                                    display={((mainNavToggle == navArr(props, 'explore') && desktopView) || (mobileNavToggle == 'mainNav' && (mobileExploreItems != null) && mobileView)) ? true : false}
                                                    // desktopNavToggle={desktopNavToggle}
                                                    //index={index}
                                                    desktopView={desktopView}
                                                    mobileExploreItems={mobileExploreItems}
                                                    // mobileNavToggle={mobileNavToggle}
                                                    mobileView={mobileView}
                                                    handleNavClose={handleNavClose}
                                                    // handleDesktopNav={handleDesktopNav}
                                                    handleMobileExplore={handleMobileExplore}
                                                    refactorURL={refactorURL}
                                                    onDealerData={(data) => {
                                                        // for mobile purposes
                                                        setDealerExplore(data)
                                                    }}
                                                />
                                            /*
                                            <MainNavContentInner normal="true" flex="true">
                                                <MainNavFirst>
                                                    {props.explore.items.map((item, index) => {
                                                        return (
                                                            ((mobileExploreItems == index) || (desktopView)) &&
                                                            <MainNavContentItem key={item.id ? item.id : index} normal="true">
                                                                {desktopView &&
                                                                    item.title &&
                                                                    <NavItem>
                                                                        <Link href={'/'}>
                                                                            <a>
                                                                                {item.title}
                                                                            </a>
                                                                        </Link>
                                                                    </NavItem>
                                                                }
                                                                {mobileView &&
                                                                    <MainNavContentItem list="true" normal="true">
                                                                        <NavItem back="true" noborder="true">
                                                                            <Link>
                                                                                <a
                                                                                    onClick={(e) => {
                                                                                        e.preventDefault()
                                                                                        handleMobileExplore(null, 100);
                                                                                    }}>
                                                                                    Back
                                                                                </a>
                                                                            </Link>
                                                                        </NavItem>
                                                                    </MainNavContentItem>
                                                                }
                                                                {item.links && item.links.length > 0 &&
                                                                    <MainNavContentItem normal="true">
                                                                        <NavContent>
                                                                            {item.links.map((item, index) => {
                                                                                return (
                                                                                    <NavItem key={item.id ? item.id : index}>
                                                                                        <Link href={item.href && refactorURL(item.href)} target={item.target && item.target}>
                                                                                            <a>
                                                                                                {item.title}
                                                                                            </a>
                                                                                        </Link>
                                                                                    </NavItem>
                                                                                )
                                                                            })
                                                                            }
                                                                        </NavContent>
                                                                    </MainNavContentItem>
                                                                }
                                                            </MainNavContentItem>
                                                        )
                                                    })

                                                    }
                                                </MainNavFirst>
                                                {desktopView &&
                                                    <MainNavSecond>
                                                        {props.explore.image &&
                                                            <RespImg
                                                                {...props.explore.image}
                                                            />
                                                        }
                                                    </MainNavSecond>
                                                }
                                                {desktopView &&
                                                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                }
                                            </MainNavContentInner> */
                                        }
                                        
                                    </MainNavContent>
                                    <MainNavContent normal="true" active={((desktopView && searchOpen) || ((mobileNavToggle == 'mainNav') && searchOpen && mobileView)) ? 1 : 0} search="true">
                                        {((desktopView && searchOpen) || ((mobileNavToggle == 'mainNav') && searchOpen && mobileView)) &&
                                            <MainNavContentInner>
                                                {desktopView && <Text variant="h3" bigtitle="true" uppercase={true}>Search {searchTitle}</Text>}
                                                {mobileView &&
                                                    <MainNavContentItem list="true" normal="true">
                                                        <NavItem back="true" noborder="true">
                                                            <Link>
                                                                <a
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleDesktopNav(navArr(props, 'nav'), 'nav', e, null, 100);
                                                                    }}>
                                                                    Back
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                    </MainNavContentItem>
                                                }
                                                <NewNavSearch
                                                    onFormSubmit={handleSearchSubmit}
                                                />
                                                {desktopView &&
                                                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                }
                                            </MainNavContentInner>
                                        }
                                    </MainNavContent>
                                    <MainNavContent normal="true" active={((desktopView && savedOpen) || ((mobileNavToggle == 'mainNav') && savedOpen && mobileView)) ? 1 : 0} saved="true">
                                        {((desktopView && savedOpen) || ((mobileNavToggle == 'mainNav') && savedOpen && mobileView)) &&
                                            <MainNavContentInner>
                                                {mobileView &&
                                                    <MainNavContentItem list="true" normal="true">
                                                        <NavItem back="true" noborder="true">
                                                            <Link>
                                                                <a
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleDesktopNav(navArr(props, 'nav'), 'nav', e, null, 100);
                                                                    }}>
                                                                    Back
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                    </MainNavContentItem>
                                                }
                                                <Text variant="h3" bigtitle="true" uppercase={true}>Your saved models</Text>
                                                <NewSavedModel />
                                                {desktopView &&
                                                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                }
                                            </MainNavContentInner>
                                        }
                                    </MainNavContent>
                                    <MainNavContent normal="true" active={(((desktopView && dealerOpen) || ((mobileNavToggle == 'mainNav') && dealerOpen && mobileView)) && (dealer && dealer.data && dealer.data.contact)) ? 1 : 0}  dealer="true">
                                    {(((desktopView && dealerOpen) || ((mobileNavToggle == 'mainNav') && dealerOpen && mobileView)) && (dealer && dealer.data && dealer.data.contact)) &&
                                            <MainNavContentInner>
                                                {mobileView &&
                                                    <MainNavContentItem list="true" normal="true">
                                                        <NavItem back="true" noborder="true">
                                                            <Link>
                                                                <a
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        handleDesktopNav(navArr(props, 'nav'), 'nav', e, null, 100);
                                                                    }}>
                                                                    Back
                                                                </a>
                                                            </Link>
                                                        </NavItem>
                                                    </MainNavContentItem>
                                                }
                                                <Text variant="h3" bigtitle="true" uppercase={true}>{dealer.title ? dealer.title : defaultDealer.title}</Text>
                                                <ContactDetails
                                                    data={dealer.data}
                                                    title={null}
                                                    heading={dealer.title}
                                                    dealer={dealer.title}
                                                    borderColor={theme.palette.middleGrey}
                                                    dealerBtnText="Locate another dealer"
                                                    ctaWidth={210}
                                                    stack={false}
                                                    dealerHref={props.countryCode.toLowerCase() === 'nz' ? '/find-a-dealer' : '/dealers'}
                                                    type="header"
                                                />
                                                {desktopView &&
                                                    <DesktopNavClose src={NavClose} onClick={(e) => { handleNavClose(100, e) }} />
                                                }
                                            </MainNavContentInner>
                                        }
                                    </MainNavContent>
                                </MainNavContentContainer>
                            </MainNavInnerContainer>
                            {desktopView &&
                                <NavContainerSecond>
                                    <NavItem onClick={(e) => onSaved(e, 100)} active={savedOpen ? 1 : 0} aria-label="saved">
                                        <NavIcon noTextSmall={true}>
                                            <NavItemIcon src={heartIcon} alt={'saved'}/>
                                            {itemsSaved > 0 && <i>{itemsSaved}</i>}
                                        </NavIcon>
                                    </NavItem>
                                    <NavItem aria-label="compare">
                                        <Link href={compareItems && compareItems.length > 0 ? `/compare?${qs.stringify({items:compareItems})}` : '/compare'}>
                                            <a aria-label="compare">
                                                <NavIcon noTextSmall={true}>
                                                    <NavItemIcon src={compareIcon} alt={'compare'} />
                                                    {(compareItems && compareItems.length) > 0 && <i>{compareItems.length}</i>}
                                                </NavIcon>
                                            </a>
                                        </Link>
                                    </NavItem>
                                    <NavItem onClick={(e) => toggleSearch(e, 100)} active={searchOpen ? 1 : 0} search="true"  aria-label="search">
                                        <NavIcon text={false}>
                                            <NavItemIcon src={searchIcon} alt={'Search'} />
                                        </NavIcon>
                                    </NavItem>
                                    {process.env.DEALER_SITE !== true ?
                                        <>
                                            {dealer && dealer.data ?
                                                <NavItem topmenu="true" menuhover="true" active={dealerOpen ? 1 : 0} onClick={(e) => handleViewDealer(e)}>
                                                    <Link href={refactorURL(dealer.href)}>
                                                        <a onClick={(e) => e.preventDefault()}>
                                                            <span>
                                                                {dealer.title ? dealer.title : defaultDealer.title}
                                                            </span>
                                                        </a>
                                                    </Link>
                                                    <NavArrow src={NavArrowDown} />
                                                </NavItem>
                                                :
                                                <NavItem topmenu="true" menuhover="true">
                                                    <Link href={refactorURL(defaultDealer.href)}>
                                                        <a>
                                                            <span>
                                                                {defaultDealer.title && defaultDealer.title}
                                                            </span>
                                                        </a>
                                                    </Link>
                                                </NavItem>
                                            }
                                        </>
                                    : <>
                                        {dealerSitelastItem &&
                                            <NavItem topmenu="true" menuhover="true">
                                                <Link href={refactorURL(dealerSitelastItem.href)}>
                                                    <a>
                                                        <span>
                                                            {dealerSitelastItem.title && dealerSitelastItem.title}
                                                        </span>
                                                    </a>
                                                </Link>
                                            </NavItem>
                                        }
                                    </>}
                                </NavContainerSecond>
                            }

                        </MainNavContainer>
                    </HeaderContainer>
                </Container>
            </Wrapper>
        </>
    )
}

MainNav.propTypes = {
    onSaved: propTypes.func,
    refactorURL: propTypes.func,
    itemsSaved: propTypes.number,
    savedOpen: propTypes.bool,
    compareItems: propTypes.array,
    countryCode: propTypes.string,
    searchOpen: propTypes.bool,
    dealerOpen: propTypes.bool,
    mainNavToggle: propTypes.number,
    desktopNavToggle: propTypes.number,
    mobileNavToggle: propTypes.string,
    tabContent: propTypes.number,
    mobileTabContent: propTypes.number,
    mobileExploreItems: propTypes.number,
    handleDesktopNav: propTypes.func,
    handleMobileExplore: propTypes.func,
    handleNavClose: propTypes.func,
    handleMobileMainToggle: propTypes.func,
    navItems: propTypes.array,
    rangeTitle: propTypes.string,
    navArr: propTypes.func,
    searchTitle: propTypes.string,
    toggleSearch: propTypes.func,
    handleSearchSubmit: propTypes.func,
    handleTabHover: propTypes.func,
    handleTabClick: propTypes.func,
    dealer: propTypes.object,
    defaultDealer: propTypes.object,
    handleViewDealer: propTypes.func,
    desktopView: propTypes.bool,
    mobileView: propTypes.bool
}

MainNav.defaultProps = {
    onSaved: () => { },
    refactorURL: () => { },
    itemsSaved: 0,
    savedOpen: null,
    compareItems: [],
    countryCode: 'AU',
    searchOpen: null,
    dealerOpen: null,
    mainNavToggle: null,
    desktopNavToggle: null,
    mobileNavToggle: null,
    tabContent: null,
    mobileTabContent: null,
    mobileExploreItems: null,
    handleDesktopNav: () => { },
    handleMobileExplore: () => { },
    handleNavClose: () => { },
    handleMobileMainToggle: () => { },
    navItems: [],
    rangeTitle: null,
    navArr: () => { },
    searchTitle: null,
    toggleSearch: () => { },
    handleSearchSubmit: () => { },
    handleTabHover: () => { },
    handleTabClick: () => { },
    dealer: {},
    defaultDealer: {},
    handleViewDealer: () => { },
    desktopView: null,
    mobileView: null
}

export {MainNavContentInner, MainNavFirst, MainNavContentItem, NavItem, NavContent, MainNavSecond, DesktopNavClose, MainNav as default}